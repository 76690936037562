<template>
  <div class="row">
    <div class="col-lg-8 m-auto">
      <card :title="$t('auth.verify_email')">
        <template v-if="success">
          <div class="alert alert-success" role="alert">
            {{ success }}
          </div>

        <router-link :to="{ name: 'register', query: {type:this.type ? 2:1,name:this.name,email:this.email} }">       
          <button type="submit" class="tw-mt-6 tw-flex tw-justify-center tw-w-20 tw-px-6 tw-py-2 tw-no-underline tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-shadow-sm tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
            {{ $t('auth.register') }}
          </button>
        </router-link>
      

        </template>
        <template v-else>
          <div class="alert alert-danger" role="alert">
            {{ error || $t('auth.failed_to_verify_email') }}
          </div>

        </template>
      </card>
    </div>
  </div>
</template>

<script>

import i18n from '@/i18n'

const qs = (params) => Object.keys(params).map(key => `${key}=${params[key]}`).join('&')

export default {
  middleware: 'guest',

  metaInfo () {
    return { title: i18n.t('verify_email') }
  },


  async beforeRouteEnter (to, from, next) {
    try {
      const { data } = await this.$http.post(`/api/invitations/email/${to.params.id}?${qs(to.query)}`)
      next(vm => {
        vm.success = data.status 
        vm.name = data.name
        vm.email = data.email
        vm.type = data.is_vendor
        })
    } catch (e) {
      next(vm => { vm.error = e.response.data.status })
    }
  },

  data: () => ({
    error: '',
    success: '',
    name: '',
    email: '',
    type: ''
  })
}
</script>
